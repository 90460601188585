import * as React from 'react'
import css from '@emotion/css'
import { mediaQueries } from '$theme/legacy'
import PostCard from './PostCard'
import { Post, Maybe } from '$utils/globals'
import { rhythm } from '$utils/typography'

type Props = {
  title?: string
  posts: Array<Maybe<Post>>
  renderActions?: React.ReactNode
}

function PostCardRow(props: Props) {
  return (
    <section
      css={css`
        width: 100%;
        margin: 0 auto;
        margin-bottom: ${rhythm(2)};
      `}
    >
      {props.title && <h2> {props.title} </h2>}
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 20px;

          ${mediaQueries.MIN_TABLET_MEDIA_QUERY} {
            grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
          }
        `}
      >
        {props.posts
          ? props.posts.map(p =>
              p && p.slug ? <PostCard key={p.slug} post={p} /> : null,
            )
          : 'No posts'}
      </div>

      {props.renderActions && <div>{props.renderActions}</div>}
    </section>
  )
}
export default PostCardRow
