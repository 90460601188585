import Button from '$components/Button'
import Page from '$components/Page'
import { PostType, Post, Maybe } from '$utils/globals'
import { groupBy } from '$utils/helpers'
import { graphql, Link } from 'gatsby'
import { Query } from 'graphqlTypes'

import * as React from 'react'
import Layout from '../components/Layout'
import PostCardRow from '../components/PostCardRow'
import { extractPostsFromAllMarkdownRemarkQueryByType } from '$utils/buildRelatedPosts'
import { oc } from 'ts-optchain'

const { memo, useMemo } = React

type Props = {
  data: Query
}

export default memo(function Index(props: Props) {
  const {
    postsMostPopular,
    guides,
    featuredAsia,
    featuredEurope,
    featuredSouthAmerica,
    featuredNorthAmerica,
  } = useMemo(
    () => {
      const { allMarkdownRemark } = props.data
      if (!allMarkdownRemark) return {}

      const posts = extractPostsFromAllMarkdownRemarkQueryByType({
        markdownData: props.data.allMarkdownRemark,
        type: PostType.Destination,
      })

      const preGuides = extractPostsFromAllMarkdownRemarkQueryByType({
        markdownData: props.data.allMarkdownRemark,
        type: PostType.Guide,
      })

      const guides = [
        preGuides.find(p => !!p && p.slug === 'our-10-favorite-destinations'),
        preGuides.find(
          p => !!p && p.slug === 'five-getaways-that-wont-break-the-bank',
        ),
        preGuides.find(p => !!p && p.slug === 'budgeting-for-long-term-travel'),
        preGuides.find(p => !!p && p.slug === 'using-airbnb-like-a-pro'),
      ]

      const videos = extractPostsFromAllMarkdownRemarkQueryByType({
        markdownData: props.data.allMarkdownRemark,
        type: PostType.Video,
      })

      // Most Popular
      const postsMostPopular = posts
        .filter(p =>
          [
            '10-days-in-peru-the-ultimate-itinerary',
            '11-unique-drinks-from-chile',
            'dos-and-donts-in-budapest',
            '10-day-road-trip-in-patagonia',
            'the-ultimate-10-day-road-trip-in-Patagonia',
            'using-airbnb-in-cuba',
            'things-to-do-in-ollantaytambo',
            '10-reasons-to-visit-vietnam',
            'tokyos-biggest-seafood-market-tsukiji-market',
          ].includes(oc(p).slug('')),
        )
        .filter(p => p && !p.language)

      const continents = groupBy(posts, 'continent')

      return {
        posts,
        postsMostPopular,
        videos,
        guides,
        continents,
        featuredNorthAmerica: pickByTag(
          'featured-north-america',
          continents['north america'] || [],
        ).sort(sortByTitle),
        featuredSouthAmerica: pickByTag(
          'featured-south-america',
          continents['south america'] || [],
        ).sort(sortByTitle),
        featuredEurope: pickByTag(
          'featured-europe',
          continents.europe || [],
        ).sort(sortByTitle),
        featuredAsia: pickByTag('featured-asia', continents.asia || []).sort(
          sortByTitle,
        ),
        types: groupBy(posts, 'type'),
        // featured: posts.filter(p => p.isFeatured === true),
      }
    },
    [props.data],
  )

  return (
    <Layout>
      <Page>
        {postsMostPopular ? (
          <PostCardRow
            title="Popular Articles"
            posts={postsMostPopular}
            renderActions={
              <Link to="/destinations">
                <Button componentType={Button.Types.Secondary}>
                  See all popular articles
                </Button>
              </Link>
            }
          />
        ) : null}

        {guides ? <PostCardRow title="Travel Guides" posts={guides} /> : null}
        {featuredAsia ? (
          <PostCardRow
            title="Scope Out Asia"
            posts={featuredAsia}
            renderActions={
              <Link to="/destinations/asia">
                <Button componentType={Button.Types.Secondary}>
                  See all Asia articles
                </Button>
              </Link>
            }
          />
        ) : null}
        {featuredSouthAmerica ? (
          <PostCardRow
            title="Explore South America"
            posts={featuredSouthAmerica}
            renderActions={
              <Link to="/destinations/south-america">
                <Button componentType={Button.Types.Secondary}>
                  See all South America articles
                </Button>
              </Link>
            }
          />
        ) : null}
        {featuredNorthAmerica ? (
          <PostCardRow
            title="Travel North America"
            posts={featuredNorthAmerica}
            renderActions={
              <Link to="/destinations/north-america">
                <Button componentType={Button.Types.Secondary}>
                  See all North America articles
                </Button>
              </Link>
            }
          />
        ) : null}
        {featuredEurope ? (
          <PostCardRow
            title="Trips in Europe"
            posts={featuredEurope}
            renderActions={
              <Link to="/destinations/europe">
                <Button componentType={Button.Types.Secondary}>
                  See all Europe articles
                </Button>
              </Link>
            }
          />
        ) : null}
      </Page>
    </Layout>
  )
})

export const pageQuery = graphql`
  query FrontPageQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            featured
            slug
            path
            type
            visibility
            country
            city
            continent
            publishedAt
            title
            tags
            language
            image {
              childImageSharp {
                fluid(
                  maxWidth: 600
                  maxHeight: 400
                  cropFocus: CENTER
                  quality: 50
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
function pickByTag(tag: string, posts: Array<Maybe<Post>>) {
  return posts.filter(p => p && p.tags && p.tags.includes(tag))
}

function sortByTitle(a: Post, b: Post) {
  return oc(a)
    .title('')
    .localeCompare(oc(b).title(''))
}
